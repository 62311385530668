//Vieets theem
.info-item .text-item p{
  font-size: 14px;
  font-weight: 700;
}
.info-item .text-item>span{
  color: #333;
  font-style: italic;
}
.action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.action-item>a{
  margin-left: 5px;
}
.select-material{
  // color: #000;
}
.list-detail-cus .collapsible-header{
  align-items: center;
}
.list-detail-cus .collapsible-header h4{
  font-size: 17px;
}
.list-detail-cus .collapsible-header i{
  font-size: 1.8rem;
  color: #444;
}
.list-detail-cus .collapsible-body{
  padding: 0px;
}
.list-detail-cus .collection .collection-item{
  border-bottom: 1px solid #c0c0c0;
}
.list-detail-cus .collapsible-body .collection-item {
    padding-left: 20px;
}
.form-inputs {
    background: #fff;
}
.card-das .card-icon .ion-ribbon-b{
  color: #1dc7ea!important;
}
.card-das .card-icon .ion-ios-list{
  color: #ff4a55!important;
}

.card-das .card-icon .ion-android-person-add{
  color: #87cb16!important;
}

.card-das .card-icon .ion-ios-gear{
  color: #ff9500!important;
}

.pag{
  text-align: center;
}

.modal{
  border-radius: 5px;
  max-height: 90%;
}
.modal-footer{
  height: 45px !important;
}
.modal-footer a{
  display: block;
  text-align: center;
  color: #fff;
}
.modal-footer .btn-flat{
  height: initial;
  line-height: initial;
}
.m-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  text-align: center;
  color:#fff;
}
.m-header h3{
  margin:0px;
  color: #fff;
}
.m-header i{
  margin-right: 5px;
}
.sfs-detail{
  display: flex;
  flex-wrap: wrap;
}
.sfs-filter{
  width: 100%;
  padding:0px 15px;
}
.sfs-col2{
    display: flex;
    width: 100%;
    // border-bottom: 1px solid #ddd;
}
.sfs-col2 .sfs-filter{
  width: 50%;
}
// .sfs-col2 .sfs-filter:nth-child(2){
//   border-left: 1px solid #ddd;
// }
.sfs-filter p{
  margin-bottom: 0px;
}
.sfs-filter h4{
    font-size: 16px;
  }

.sfs-border ul li p {
    margin-bottom: 5px;
    border-bottom: 1px solid #ccc;
}
.avatar-body p{
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 1 !important;
    font-size: 16px !important;
}
.avatar-body p a{
    color: #fff !important;
    padding: 10px 0px !important;
}
#slide-out{
    width: 300px !important;
    background: #2196F3;
  }
  .back-menu, .back-to-page {
    position: relative;
    display: block;
    padding: 12px 10px !important;
    background: #e53935;
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 22px !important;
    float: none !important;
    font-size: 15px;
    text-transform: uppercase;
}
.back-menu a, .back-to-page a{
  height: initial;
  line-height: initial;
}
.back-menu i, .back-to-page i {
    position: absolute;
    left: 15px;
    font-size: 16px;
    width: 20px;
    height: 20px;
    background-color: #2196f3;
    border-radius: 100%;
    line-height: 22px;
    text-align: center;
}
  .menu-fixed-side > ul li a {
    display: block;
    height: initial;
    line-height: 22px;
    position: relative;
    padding: 10px;
    padding-left: 15px;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    text-transform: capitalize;
    border-bottom: 1px solid rgba(255,255,255, .1);
}
.menu-fixed-side > ul li a span {
    position: absolute;
    right: 0px;
    display: inline-block;
    text-align: left;
    width: 60px;
    top: 0;
    bottom: 0;
    line-height: 38px;
}
.menu-fixed-side > ul li a span small {
    padding: 0px 10px;
    width: 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    font-size: 12px;
}
.menu-fixed-side > ul li a span .ion-ios-arrow-right {
    position: absolute;
    right: 10px;
    font-size: 18px;
    line-height: 36px;
}
.menu-fixed-side > ul li ul {
    position: fixed;
    background-color: #fff;
    min-height: 100%;
    z-index: 9999;
    width: 300px;
    top: 0;
    right: 0px;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease-in-out;
    opacity: 1;
    background: #232f3e;
    -ms-transform: translateX(300px);
    -webkit-transform: translateX(300px);
    transform: translateX(300px);
}
.show-sb {
    -ms-transform: translateX(0px) !important;
    -webkit-transform: translateX(0px) !important;
    transform: translateX(0px) !important;
}

input[type="checkbox"].ios8-switch:checked + label:before{
  -webkit-transform: initial;
     transform: initial; 
}
input[type="checkbox"].ios8-switch:not(.filled-in) + label:after {
    -webkit-transform: initial; 
    transform: initial; 
}

input[type="checkbox"].ios8-switch {
    position: absolute;
    margin: 8px 0 0 16px;    
}
input[type="checkbox"].ios8-switch + label {
    position: relative;
    padding: 5px 0 0 50px;
    line-height: 2.0em;
}
input[type="checkbox"].ios8-switch + label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px; /* x*5 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:after {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    top: 0px;
    width: 24px; /* x*3 */
    height: 24px; /* x*3 */
    border-radius: 16px; /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
input[type="checkbox"].ios8-switch + label:hover:after {
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch:checked + label:after {
    margin-left: 16px;
}
input[type="checkbox"].ios8-switch:checked + label:before {
    background: #55D069;
}

/* SMALL */

input[type="checkbox"].ios8-switch-sm {
    margin: 5px 0 0 10px;
}
input[type="checkbox"].ios8-switch-sm + label {
    position: relative;
    padding: 0 0 0 32px;
    line-height: 1.3em;
}
input[type="checkbox"].ios8-switch-sm + label:before {
    width: 25px; /* x*5 */
    height: 15px; /* x*3 */
    border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:after {
    width: 15px; /* x*3 */
    height: 15px; /* x*3 */
    border-radius: 10px; /* x*2 */
}
input[type="checkbox"].ios8-switch-sm + label:hover:after {
    box-shadow: 0 0 3px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-sm:checked + label:after {
    margin-left: 10px; /* x*2 */
}

/* LARGE */

input[type="checkbox"].ios8-switch-lg {
    margin: 10px 0 0 20px;
}
input[type="checkbox"].ios8-switch-lg + label {
    position: relative;
    padding: 7px 0 0 60px;
    line-height: 2.3em;
}
input[type="checkbox"].ios8-switch-lg + label:before {
    width: 50px; /* x*5 */
    height: 30px; /* x*3 */
    border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:after {
    width: 30px; /* x*3 */
    height: 30px; /* x*3 */
    border-radius: 20px; /* x*2 */
}
input[type="checkbox"].ios8-switch-lg + label:hover:after {
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
}
input[type="checkbox"].ios8-switch-lg:checked + label:after {
    margin-left: 20px; /* x*2 */
}

@media (max-width: 500px){
  .hd-500{
    display: none;
  }
}
@media (max-width: 330px){
  .sfs-filter h4{
    font-size: 14px;
  }
  .sfs-filter{
    padding:15px 10px;
  }
}
@media only screen and (max-width: 992px)
{
  .modal {
    width: 95%;
}
}


/*# sourceMappingURL=main.css.map */
/*.select-wrapper input.select-dropdown {
    color: #9e9e9e;}*/
.btn-submit{
  display: block;
  width: 100%;
  color: #fff;
}
.import-file{
    padding: 13px 10px;
    font-size: 16px;
    color: #fff;
    display: block;
    background: #2196F3;
    text-align: center;
    text-transform: capitalize;
    border: 0;
    width: 100%;
}
.list-style-item .info-item .text-item{
  width: calc(100% - 72px);
}


.list-das {
        margin-top: 30px;
      }
      .card-das{
        border-radius: 4px;
        -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 0 0 1px rgba(63,63,68,.1);
        box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 0 0 1px rgba(63,63,68,.1);
        background-color: #fff;
        margin-bottom: 10px;
      }
      .card-das .content {
          padding: 15px 15px 10px;
      }
      .card-das .content .card-body{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .card-das .card-icon i{
        font-weight: 700;
        line-height: 59px;
        font-size: 50px;
        color: #ff9500;
      }
      .card-das .card-text{
          display: block;
          font-size: 28px; 
          text-align: right;
      }
      .card-das .card-text p{
          margin: 0px;
          font-size: 16px;
          line-height: 1.5;
      }
      .card-das .card-footer{
          padding: 0;
          background-color: transparent;
          line-height: 30px;
      }
      .card-das .card-footer hr{
        border-color: #ddd;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 0;
        border-top: 1px solid #eee;
      }
      .card-das .card-footer p{
        display:flex;
        margin-bottom: 0px;
        justify-content: space-between;
      }
      .card-das .card-footer p i{
        margin-right: 5px;
      }
      .list-das .col{
        padding:0 0.55rem;
      }
      .list-chart .card{
        -webkit-box-shadow: none;
        box-shadow: none;
      }
      @media (max-width:991px){
        .list-das .col{
          width:50%;
        }
        .list-chart .col{
            width: 50%;
          }
        @media (max-width: 768px){
          .list-das {
              margin-top: 15px;
          }
          div#pie_chart,
          #doughnut_chart{
            margin-top:0px;
          }
          .info-item > img, .info-item > i{
            margin:0 10px;
          }
          .list-chart .col{
            width: 100%;
          }
        }
        @media (max-width: 414px){
          .list-das .col{
            width:100%;
          }
          .list-chart .card{
            padding:20px 0px;
          }
          .home-list-style .list-style-item .info-item>i{
            display: none;
          }
          .home-list-style .list-style-item .info-item .text-item{
            width: calc(100%);
          }
          .list-chart .container .row {
              margin-bottom: 0px;
          }
        }
      }


@media (min-width: 1200px){
  .card-das .card-text sup{
    font-size: 13px;
  }
}




.list-option{
  li{
    display: inline-block;
    padding-left: 15px;
  }
}


.table-csdl{
  margin-top: 5px;
  font-size: 14px;
  word-break: break-all;
  tr{
    th{
      background-color: #ddd;
      text-transform: capitalize;
      color: #000;
      border: 1px solid #000;
    }
    td{
      padding: 5px 5px;
      border: 1px solid #000;
      color: #000;
      &:first-child{
        font-weight: 700;

      }
      &.uathich{
        color: #f00;
        font-weight: 700;
        background-color: #fff;
        font-size: 15px;
      }
      &.bold{
        color: #000;
        font-weight: 700;
        font-size: 15px;
      }
    }
    .stt{
      background-color: #f44336;
      color: #fff;
    }
    
  }
}


.search-bar{
  background-color: #eee;
  display: inline-block;
  width: 100%;
  padding-bottom: 15px;

  .row{
    margin-bottom: 0px;
    .input-field{
      margin-top: 0px;
      margin-bottom: 15px;
    }
    input{
      margin: 0px;
    }
    .btn{
      margin-left: 10px;
    }
  }
}



.nofavorite,
.okfavorite{
    position: relative;
    padding-right: 25px;
}
.nofavorite:before,
.okfavorite:before{
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 1px solid #26a69a;
    border-radius: 1px;
    position: absolute;
    right: 0px;
    content: "";
}
.nofavorite:before{
    border-color: #607d8b !important;
}
.okfavorite:after{
    width: 9px;
    height: 9px;
    background-color: #26a69a;
    position: absolute;
    right: 5px;
    top: 4px;
    content: "";
}



.top-list{
  display: inline-block;
  padding: 0px;
  margin: 0px;
  padding-left: 30px !important;
  li{
    display: inline-block;
    border-right: 1px solid #459dea;
    padding: 0px 15px;
    &:first-child{
      border-left: 1px solid #459dea;
    }
  }
}

@media(max-width: 767px){
  .top-list{
    float: right;
    padding-left: 0px;
    li{
      padding: 0px 5px;
      border: none;
      &:first-child{
        border-left: none;
      }
    }
  }
}
.ie-port{
  display: inline-block;
  width: 100%;
  li{
    display: inline-block;
    margin-left: 30px;
  }
}

@media(max-width: 992px){
  .scroll-mb{
    overflow-x: scroll;
  }
}

.input-field{
  lable{
    color: #000 !important;
  }
}

strong{
  color: #333;
  font-weight: 700;
}



.traloi{
  display: inline-block;
  width: 100%;
  background-color: #eee;
  padding: 5px 15px !important;
  border-radius: 5px;
  li{
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    a{
      color: #f00;
      font-size: 12px;
    }
    .admintlcon{
      background-color: #fff;
      border-radius: 5px;
      display: inline-block;
      width: 100%;
      padding: 5px !important;
      margin: 5px 0px;
      li{
        color: #000;
        font-weight: 700;
        margin-bottom: 0px;
        span{
          font-weight: 400;
        }
        
      }
    }
  }
}


.not-show{
  opacity: 0;
  visibility: hidden;
  color: #ffc107 !important;
  padding-left: 5px;
}
.care_view{
  opacity: 1;
  visibility: visible;
  
}