
/*------------------------------------------------------------------
[Table of contents]
1. Typography 
2. Utility & Colors
3. Layout
4. Animation
5. Toolbar
6. Sidebars
7. Elements
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
1. Typography
-------------------------------------------------------------------*/
.container{
    width: 100%;
}
body {
    /*font-family: 'Open Sans', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    background: #fff;
    color: #76838f;
    overflow-x: hidden;
}
[class^="mdi-"],
[class*="mdi-"] {
    font-family: "Ionicons";
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2196f3;
    text-shadow: rgba(0, 0, 0, .12) 0 0 1px;
    margin: 10px 0;
}
h1 {
    font-size: 32px;
    line-height: 1.25em;
}
h2 {
    font-size: 26px;
    line-height: 1.15em;
}
h3 {
    font-size: 22px;
    line-height: 1.13em;
}
h4 {
    font-size: 18px;
    line-height: 1.11em;
}
h5 {
    font-size: 16px;
    line-height: 1em;
}
p {
    font-size: 16px;
    margin: 0 0 10px;
}
a {
    color: inherit;
}
a,
a:link,
a:hover,
a:active,
a:focus {
    outline: 0;
    text-decoration: none;
}
body,
input,
textarea,
button,
select,
label,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
b {
    font-weight: 700;
}
.small,
small {
    font-size: 85% !important;
}
img {
    width: 100%;
    height: auto;
    display: block;
}
.uppercase {
    text-transform: uppercase;
    font-weight: 700;
    color: #444;
}
h1.uppercase,
h2.uppercase,
h3.uppercase {
    letter-spacing: 3px;
    margin-right: -3px;
}
h4.uppercase,
h5.uppercase,
h5.uppercase {
    letter-spacing: 2px;
    margin-right: -2px;
}
i {
    font-size: 24px;
    color: inherit;
}
i.big {
    font-size: 100px;
    width: 150px;
    display: block;
    margin: 0 auto 30px;
    border: 2px solid;
    border-radius: 50%;
    background: #2196F3;
    color: #fff;
}
blockquote {
    font-size: 1.5em;
    margin: 30px 0;
    color: #444;
}
.title {
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-left: -3px;
    font-weight: 700;
}
.section-title {
    margin: 0;
    padding: 40px 20px 0;
}
.text-small {
    font-size: 0.9em;
    font-weight: 100;
}
.text-small i {
    font-size: 1.4rem;
}
/*------------------------------------------------------------------
2. Utility & Colors
-------------------------------------------------------------------*/
/* Colors */

.accent-text {
    color: #FF4081 !important;
}
.accent-color {
    background-color: #FF4081 !important;
}
.accent-border {
    border-color: #FF4081 !important;
}
.primary-text {
    color: #2196F3 !important;
}
.primary-color {
    background-color: #2196F3 !important;
}
.primary-border {
    border-color: #2196F3 !important;
}
.grey-blue {
    background-color: #f1f4f5;
}
.text-color {
    color: #76838f !important;
}
.text-light {
    font-weight: 400;
    color: #B2B2B4;
}
.transparent {
    background-color: transparent !important;
}
.no-shadow {
    box-shadow: none;
}
/* Misures */

.width-100 {
    width: 100% !important;
}
.m-0 {
    margin: 0px !important;
}
.m-10 {
    margin: 10px !important;
}
.m-20 {
    margin: 20px !important;
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-30 {
    margin-bottom: 30px !important;
}
.m-b-40 {
    margin-bottom: 40px !important;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-t-15 {
    margin-top: 15px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.m-t-20 {
    margin-top: 20px !important;
}
.m-t-30 {
    margin-top: 30px !important;
}
.m-t-40 {
    margin-top: 40px !important;
}
.m-r-5 {
    margin-right: 5px !important;
}
.m-l-0 {
    margin-left: 0px !important;
}
.m-l-r-20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
}
.p-b-5em {
    padding-bottom: 5em !important;
}
.p-10 {
    padding: 10px !important;
}
.p-20 {
    padding: 20px !important;
}
.p-t-20 {
    padding-top: 20px !important;
}
.p-b-20 {
    padding-bottom: 20px !important;
}
.m-l-0 {
    margin-left: 0 !important;
}
.p-l-r-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.p-l-r-50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
}
/* Utility */

.block {
    display: block;
}
.overflow {
    overflow: auto;
}
.clr {
    clear: both;
}
.bottom-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
.bottom {
    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
}
.bottom-abs {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}
.page-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}
#blockui {
    /* Prevent link from triggering while drag over */
    
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: none;
}
/* Background & Overlay */

.bg-1 {
    background: url(../img/1.jpg) no-repeat center / cover !important;
}
.bg-2 {
    background: url(../img/2.jpg) no-repeat center / cover !important;
}
.bg-3 {
    background: url(../img/3.jpg) no-repeat center / cover !important;
}
.bg-4 {
    background: url(../img/4.jpg) no-repeat center / cover !important;
}
.bg-5 {
    background: url(../img/5.jpg) no-repeat center / cover !important;
}
.bg-9 {
    background: url(../img/9.jpg) no-repeat center / cover !important;
}
.bg-v-1 {
    background: url(../img/v1.jpg) no-repeat center / cover !important;
}
.bg-v-2 {
    background: url(../img/v2.jpg) no-repeat center / cover !important;
}
.bg-v-3 {
    background: url(../img/v3.jpg) no-repeat center / cover !important;
}
.bg-v-4 {
    background: url(../img/v4.jpg) no-repeat center / cover !important;
}
.bg-blur {
    background: url(../img/blur.jpg) no-repeat center / cover !important;
}
.bg-opacity {
    background: url(../img/opacity.jpg) no-repeat center / cover !important;
}
.bg-shop {
    background: url(../img/banner.jpg) no-repeat center / cover !important;
}
.opacity-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0) -webkit-gradient(linear, left top, left bottom, color-stop(48%, rgba(255, 255, 255, 0)), color-stop(90%, rgba(255, 255, 255, .85)), to(#fff)) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 48%, rgba(255, 255, 255, .85) 90%, #fff 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -o-linear-gradient(top, rgba(255, 255, 255, 0) 48%, rgba(255, 255, 255, .85) 90%, #fff 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(255, 255, 255, 0) 48%, rgba(255, 255, 255, .85) 90%, #fff 100%) repeat scroll 0 0;
}
.opacity-overlay-black {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0) -webkit-gradient(linear, left top, left bottom, color-stop(48%, rgba(0, 0, 0, 0)), color-stop(90%, rgba(0, 0, 0, .85)), to(#000)) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, .85) 90%, #000 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -o-linear-gradient(top, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, .85) 90%, #000 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, .85) 90%, #000 100%) repeat scroll 0 0;
}
.opacity-overlay-top {
    opacity: 0.7;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0) -webkit-gradient(linear, left bottom, left top, color-stop(70%, rgba(0, 0, 0, 0)), color-stop(95%, rgba(0, 0, 0, .75)), to(#333)) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, .75) 95%, #333 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) -o-linear-gradient(bottom, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, .75) 95%, #333 100%) repeat scroll 0 0;
    background: rgba(0, 0, 0, 0) linear-gradient(to top, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, .75) 95%, #333 100%) repeat scroll 0 0;
}
.border-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.3);
}
/*------------------------------------------------------------------
3. Layout
-------------------------------------------------------------------*/

.blank-layout,
.fullscreen {
    width: 100%;
    height: 100vh;
}
.page {
    margin-top: 56px;
    overflow: auto;
}
.none {
    display: none;
}
.h-banner {
    height: 212px;
    position: relative;
    z-index: 1;
}
.h-banner .parallax {
    height: 100%;
}
.h-banner .parallax img {
    display: block;
}
.banner-title {
    line-height: 30px;
    padding-left: 30px;
    font-size: 25px;
}
.banner-title {
    position: absolute;
    left: 33px;
    bottom: 30px;
    color: #ffffff;
}
.banner-title {
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    line-height: 56px;
    margin-left: 9px;
    text-transform: none;
    letter-spacing: normal;
    z-index: 9;
}
.banner {
    height: 300px;
    margin-top: 56px;
    position: relative;
}
.banner .parallax {
    height: 100%;
}
.halo-nav {
    height: 56px;
    position: fixed;
    z-index: 2;
    top: 0px;
    background: none;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
}
.halo-nav {
    background: none;
}
.box-shad-none {
    box-shadow: none;
}
.halo-nav.smaller {
    height: 56px;
}
.halo-nav.smaller span {
    bottom: 15px;
}
.h-banner .floating-button {
    position: absolute;
    bottom: -25px;
    right: 20px;
}
.halo-nav.h-bg {
    background-color: #2196F3;
}
.subtitle {
    position: absolute;
    left: 73px;
    bottom: 71px;
    color: #fff;
    opacity: 0.6;
}
/* Page Header */

.hero-header {
    position: relative;
    z-index: 1;
}
.hero-header .floating-button {
    position: absolute;
    bottom: -27px;
    right: 20px;
    z-index: 2;
}
.floating-button.scrollable-fab.scrolled-down {
    position: fixed;
    bottom: 20px;
}
.hero-header .hero-over {
    position: absolute;
    bottom: 20px;
    left: 20px;
    margin: 0;
}
/* Page Body */

.page-content {
    padding: 30px 20px;
}
#content {
    overflow: auto;
}
/* Footer */

footer.page-footer {
    margin-top: 0;
}
.footer-copyright .right > i {
    margin-left: 10px;
    font-size: 20px;
}
/*------------------------------------------------------------------
4. Animation
-------------------------------------------------------------------*/

.m-scene .animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.m-scene .fadein {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
}
.m-scene .fadeinup {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}
.m-scene .fadeindown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}
.m-scene .fadeinright {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}
.m-scene .bouncein {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}
.m-scene.is-exiting .animated {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}
.delay-1 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.delay-2 {
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
}
.delay-3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.delay-4 {
    -webkit-animation-delay: 0.55s;
    animation-delay: 0.55s;
}
.delay-5 {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}
.delay-6 {
    -webkit-animation-delay: 0.95s;
    animation-delay: 0.95s;
}
/*------------------------------------------------------------------
5. Toolbar
-------------------------------------------------------------------*/
/* Drag Trigger */

.drag-target {
    width: 1px !important;
    top: 56px;
}
/* Toolbar */

#toolbar {
    width: 100%;
    height: 56px;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
}
#toolbar.transparent {
    background-color: transparent !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}
.open-left {
    float: left;
    height: 56px;
    line-height: 56px;
    width: 56px;
    text-align: center;
    position: relative;
}
.open-right {
    float: right;
    height: 56px;
    line-height: 56px;
    width: 56px;
    text-align: center;
    position: relative;
}
#toolbar i {
    font-size: 24px;
    line-height: 56px;
    color: #fff;
}
#toolbar .title {
    font-size: 22px;
    font-weight: 300;
    color: #fff;
    line-height: 56px;
    margin-left: 16px;
    text-transform: none;
    letter-spacing: normal;
}
/*------------------------------------------------------------------
6. Drawer
-------------------------------------------------------------------*/

.side-nav.collapsible {
    border: none;
}
.side-nav a {
    height: 48px;
    line-height: 48px;
    padding: 0;
}
.side-nav .no-child {
    padding: 16px;
    margin: 0;
    min-height: auto;
    color: #444;
    background-color: transparent;
    border: none;
    line-height: inherit;
    height: inherit;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: initial;
    line-height: initial;
}
.side-nav .no-child i {
    height: auto;
    line-height: 100%;
    width: 2rem;
    font-size: 1.6rem;
    display: block;
    float: left;
    text-align: center;
    margin-right: 1rem;
}
.sidenav-header {
    width: 100%;
    position: relative;
    overflow: auto;
}
.sidenav-header .nav-avatar {
    display: flex;
    align-items: center;
    line-height: initial;
}
.sidenav-header .avatar {
    margin: 20px;
    height: 64px;
    width: 64px;
}
.sidenav-header  .name-menu{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.sidenav-header .avatar-body {
    flex: 1;
}
.sidenav-header .avatar-body h3 {
    color: #fff;
    margin: 0;
}
.sidenav-header .avatar-body p {
    color: #fff;
    opacity: 0.8;
    font-size: 80%;
    margin: 0;
}
.sidenav-header .nav-social {
    padding: 20px 20px 0 20px;
    line-height: initial;
}
.sidenav-header .nav-social i {
    font-size: 16px;
    color: #fff;
    margin-right: 18px;
}
/* dropdown */

.side-nav li {
    padding: 0;
}
.side-nav li:hover,
.side-nav li.active {
    background-color: transparent;
}
.side-nav .no-child:hover,
.side-nav .active .collapsible-header,
.side-nav .active .collapsible-body li a:hover {
    color: #2196F3;
    opacity: 1;
}
.side-nav .collapsible-header.active:before {
    content: "";
    width: 10px;
    height: 10px;
    background: #2196F3;
    position: absolute;
    left: -5px;
    border-radius: 100%;
    margin-top: 0px;
}
.side-nav .collapsible-header {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    line-height: initial;
    padding: 16px;
    margin: 0;
    min-height: auto;
    color: #444;
}
.side-nav .collapsible-header i {
    height: auto;
    line-height: 100%;
}
.side-nav .collapsible-body li a {
    margin: 0 16px 0 0;
    padding-left: 22px;
}
.side-nav li.active .collapsible-body li {
    background-color: #f4f8fb;
}
.side-nav li.active .collapsible-body li a {
    opacity: 0.8;
}
/* Right Sidebar */

.sidenav-header nav {
    border-bottom: 1px solid #eee;
}
/* Sidebar Chat */

.chat-sidebar {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
    position: relative;
}
.chat-img img {
    margin-right: 10px;
    margin-top: 4px;
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 50%;
    line-height: 48px;
    text-align: center;
    position: relative;
    flex: 1;
}
.chat-img .dot {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 2px solid #fff;
    position: absolute;
    top: 35px;
    left: 22px;
}
.chat-message {
    flex: 1;
    font-size: 12px;
    line-height: initial;
}
.chat-message p {
    margin: 0;
    font-size: 14px;
    color: #444;
}
.chat-message .small {
    opacity: 0.4;
    display: block;
}
/* badge */

.side-nav span.badge {
    min-width: auto;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    border-radius: 2px;
    color: #fff;
    margin: 0;
    padding: 0;
}
/*------------------------------------------------------------------
7. Elements
-------------------------------------------------------------------*/
/* Heading */

.heading-container {
    padding: 30px 20px;
}
.heading {
    margin: 0;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 1.5;
}
.heading:after {
    content: '';
    display: block;
    margin-top: 15px;
    margin-bottom: 25px;
    width: 40px;
    border-top: 2px solid #444;
}
/* Progress */

.progress {
    margin: 0;
}
/* Avatar */

.avatar {
    width: 40px;
    height: 40px;
}
.unknow-avatar {
    position: absolute;
    top: 10px;
    left: 10px;
    border-radius: 50%;
}
.unknow-avatar span {
    display: block;
    font-size: 24px;
    color: #ffffff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 100;
}
.avatar.with-letter span {
    line-height: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 100;
    display: block;
}
/* Tab */

.tabs .tab a,
.tabs .tab a:hover {
    color: #2196F3;
    opacity: 0.6;
}
.tabs .tab a.active {
    color: #2196F3;
    opacity: 1;
}
.tabs .indicator {
    background-color: #2196F3;
}
/* Searchbar */

.input-field input[type=search] ~ i,
.input-field input[type=search] ~ i {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    transition: .3s color;
}
.input-field input[type=search]:focus + label i,
.input-field input[type=search]:focus ~ i,
.input-field input[type=search]:focus ~ i {
    color: #444;
}

.search-notrans,
.search-notrans.active {
     -webkit-transform: none !important; 
     transform: none !important; 
}

/* input */

input[type=text]:focus:not([readonly])+label,
input[type=password]:focus:not([readonly])+label,
input[type=email]:focus:not([readonly])+label,
input[type=url]:focus:not([readonly])+label,
input[type=time]:focus:not([readonly])+label,
input[type=date]:focus:not([readonly])+label,
input[type=datetime-local]:focus:not([readonly])+label,
input[type=tel]:focus:not([readonly])+label,
input[type=number]:focus:not([readonly])+label,
input[type=search]:focus:not([readonly])+label,
textarea.materialize-textarea:focus:not([readonly])+label,
.input-field .prefix.active {
    color: #2196F3;
}
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #2196F3;
    box-shadow: 0 1px 0 0 #2196F3;
}
[type="radio"]:checked+label:after {
    border: 2px solid #2196F3;
    background-color: #2196F3;
}
[type="checkbox"]:checked+label:before {
    border-right: 2px solid #2196F3;
    border-bottom: 2px solid #2196F3;
}
/*Select Material*/
.select-material{
    color:#9e9e9e;
}
/* Blog Fullwidth */

.blog-fullwidth {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}
.blog-fullwidth .blog-header {
    padding: 20px 20px 20px 80px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    color: #fff;
}
.blog-fullwidth .blog-header img {
    position: absolute;
    top: 20px;
    left: 20px;
}
.blog-fullwidth .blog-author span {
    display: block;
    font-size: 14px;
}
.blog-fullwidth .blog-image {
    position: relative;
}
.blog-fullwidth .blog-preview h4 {
    margin-top: 0;
}
/* Blog Card */

.blog-card {
    margin: 20px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.blog-card .blog-header {
    padding: 20px 20px 20px 80px;
    position: relative;
}
.blog-card .blog-header img {
    position: absolute;
    top: 20px;
    left: 20px;
}
.blog-card .blog-author span {
    display: block;
    font-size: 14px;
}
.blog-fullwidth .blog-preview h4 {
    margin-top: 0;
}
/* Promo */

.promo {
    margin-bottom: 20px;
}
.promo i {
    margin: 0;
    font-size: 96px;
    display: block;
}
h6.promo-caption {
    font-size: 22px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 30px;
}
.promo p {
    padding: 0 20px;
}
/* Features List */

.feature-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}
.feature-list-title {
    flex: 1;
}
.feature-list i {
    color: #fff;
    font-size: 36px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    margin-right: 20px;
    text-align: center;
    line-height: 64px;
}
.feature-list-title h6 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 2px 0 10px;
    text-transform: uppercase;
}
/* Project */

.project-image {
    margin: 20px;
}
.project-info {
    padding: 0 20px;
}
.project-info h2 {
    margin-bottom: 0;
}
.project-social-share {
    position: absolute;
    right: 0;
    top: -4px;
}
.project-social-share i {
    margin-left: 10px;
}
.project-comments .comments-list .author {
    margin-bottom: 0;
}
/* Card */

.card {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
/* Comments */

.comments {
    padding: 20px;
    border-top: 1px solid #ddd;
}
.comments h3 {
    font-size: 16px;
    margin-bottom: 20px;
}
.comments-list,
.comments-list ul {
    width: 100%;
    overflow: hidden;
}
.comments-list li {
    margin-bottom: 32px;
}
.comments-list .avatar {
    position: absolute;
    left: 20px;
}
.comments-list .comment-body {
    padding-left: 56px;
    margin-bottom: 30px;
    width: auto;
}
.comments-list .comment-body p {
    font-size: 1em;
}
.comments-list .author {
    margin-bottom: 8px;
    display: inline-block;
}
.comments-list .date {
    float: right;
    font-size: 1em;
    opacity: 0.6;
}
li.your-comment {
    padding-left: 56px;
    margin-bottom: 0;
}
.post-author {
    position: relative;
    margin-bottom: 32px;
}
.post-author .avatar {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
}
.post-author span {
    padding-left: 38px;
    padding-top: 4px;
    display: block;
}
/* Calendar */

.calendar table {
    border-collapse: collapse;
    border-spacing: 0;
}
.calendar td,
.calendar th {
    padding: 0;
}
.calendar {
    text-align: center;
    padding: 0 20px 20px;
}
.calendar thead {
    color: #fff;
    opacity: 0.9;
    border: none;
}
.calendar tbody {
    color: #fff;
}
.calendar tbody td:hover {
    background-color: rgba(255, 255, 255, .2);
}
.calendar td {
    border: 2px solid transparent;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    width: calc(13vw - 16px);
    height: calc(13vw - 16px);
    line-height: calc(13vw - 18px);
    margin: 5px;
}
.calendar .prev-month,
.calendar .next-month {
    opacity: 0.4;
}
.current-day {
    background: #fff;
    color: #FF4081;
}
.calendar tbody td.current-day:hover {
    background-color: rgba(255, 255, 255, 1);
}
.event {
    cursor: pointer;
    position: relative;
}
.event:after {
    background: #fff;
    border-radius: 50%;
    bottom: 3px;
    display: block;
    content: '';
    height: 4px;
    left: 50%;
    margin: 0px 0 0 -2px;
    position: absolute;
    width: 4px;
}
.event.current-day:after {
    background: #fff;
}
/* Activity */

.activity {
    margin: 0 20px;
    padding: 20px 0;
    border-bottom: 1px solid #eee;
    position: relative;
}
.activity p {
    margin: 0;
}
.activity-time span,
.activity-time i {
    vertical-align: middle;
}
.activity-type {
    position: absolute;
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    text-align: center;
    background-color: #ccc;
    margin: 20px 0 0;
}
.activity-type.second-type {
    right: 50px;
}
.activity-type i {
    font-size: 24px;
    color: #fff;
}
/* Lightbox */

.pswp i {
    color: #fff;
}
/* Shop */

.shop-banner {
    width: 50%;
    float: left;
    position: relative;
}
.shop-banner h3 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 700;
}
.shop-banner p {
    text-transform: uppercase;
    margin-bottom: 0;
    font-weight: 100;
}
/* Category Shop */

.shop-category {
    position: relative;
    width: 50%;
    margin-bottom: 20px;
    float: left;
    padding: 5px;
}
.category-image {
    position: relative;
}
.category-header {
    position: absolute;
    padding: 20px;
    top: 0;
    left: 0;
    right: 0;
}
.category-header .ion-heart {
    opacity: 0.6;
    color: #d00;
}
.shop-category .category-preview h4 {
    margin-top: 0;
}
.shop-category .category-preview .price {
    margin-top: 0;
    margin-bottom: 0;
}
.rating {
    margin-bottom: 15px;
}
.rating i {
    opacity: 0.15;
    display: inline-block;
    font-size: 18px;
}
.rating i.active {
    opacity: 0.9;
    color: #fdd835;
}
/* Sizes */

.sizes .size {
    display: inline-block;
    text-transform: uppercase;
    padding: 8px 16px;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin-right: 8px;
    margin-bottom: 8px;
}
.sizes .size:hover {
    border: 1px solid #2196F3;
    background-color: #2196F3;
    color: #fff;
}
/* Sliders */

.swiper-container {
    width: 100%;
    height: 100%;
    margin: 0px auto;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
}
.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
}
.swiper-pagination-bullet-active {
    background: #2196F3;
}
.swiper-pagination.white-bullet .swiper-pagination-bullet {
    background-color: #fff;
}
/* Fullscreen */

.slider-fullscreen .bottom-abs {
    bottom: 40px;
    padding: 0 20px;
}
.slider-category {
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: 1px solid #fff;
    display: inline-block;
}
.slider-title {
    line-height: 1.2;
    font-size: 28px;
}
.slider-text {
    opacity: 0.8;
    margin-bottom: 40px;
}
/* Sliced */

.slider-sliced .swiper-slide {
    width: 100vw !important;
    height: 100vh;
    background-color: transparent;
    overflow: hidden;
}
.slider-sliced .slider-bottom-right {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    padding: 0 50px;
}
.slider-sliced .slider-bottom-right:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    right: 0;
    top: -90px;
    border-bottom: 90px solid #fff;
    border-left: 768px solid rgba(255, 255, 255, 0);
    /* Maintain smooth edge of triangle in FF */
    
    -moz-transform: scale(.9999);
}
.slider-sliced .slider-bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #fff;
    padding: 0 50px;
}
.slider-sliced .slider-bottom-left:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    left: 0;
    top: -90px;
    border-bottom: 90px solid #fff;
    border-right: 768px solid rgba(255, 255, 255, 0);
    /* Maintain smooth edge of triangle in FF */
    
    -moz-transform: scale(.9999);
}
.slider-sliced .swiper-container-horizontal>.swiper-pagination .swiper-pagination-bullet {
    margin: 0 10px;
}
/* steps */

.slider-steps {
    width: 100%;
    height: 100vh;
}
.slider-steps .valign-wrapper {
    height: 100%;
}
.slider-steps .valign {
    margin-top: -100px;
    width: 100%;
}
.steps-controllers {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    border-top: 1px solid #ddd;
    background-color: #fff;
}
.steps-controllers > div {
    width: 33.3333%;
    float: left;
    text-align: center;
    padding: 20px 0;
    border-right: 1px solid #ddd;
}
.steps-controllers > div:last-child {
    border-right: none;
}
.steps-controllers > div a {
    width: 100%;
}
.steps-controllers .swiper-button-next,
.steps-controllers .swiper-button-prev {
    background-image: none;
    left: auto;
    right: auto;
    top: auto;
    margin-top: auto;
    position: static;
    height: auto;
    padding: 20px 0;
}
/* drawer */

.swiper-container.slider-drawer {
    height: 100vh;
}
.slider-drawer .swiper-slide {
    width: calc(100% - 16px) !important;
    height: calc(100vh - 112px);
    overflow: hidden;
    margin: 64px 8px 32px;
    padding: 16px;
    box-sizing: border-box;
}
.slider-drawer .drawer-pagination {
    bottom: 6px;
}
.slider-drawer .swiper-pagination-bullet-active {
    background: #fff;
}
.slider-drawer .row .col span {
    font-size: 0.7em;
    display: block;
}
.slider-drawer .row .col i {
    font-size: 40px;
}
#grandparent {
    height: 100%;
}
.child {
    margin-bottom: 0;
}
.slider-vertical .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    height: calc(100vh - 56px) !important;
    /* Center slide text vertically */
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
/* Tweet */

.twitter {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}
.tweet {
    margin-bottom: 20px;
    line-height: initial;
}
.tweet h3 {
    font-size: 12px;
    margin: 0 0 5px;
    opacity: 0.4;
    webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.tweet h3:hover {
    opacity: 1;
}
.tweet p {
    font-size: 12px;
}
.tweet p a {
    font-size: 12px;
    display: inline;
    height: auto;
    line-height: normal;
    padding: 0;
}
/* Facebook */

.facebook {
    padding: 10px 0;
}
.face-notification {
    display: flex;
    align-items: flex-start;
    padding: 10px 0;
}
.face-notification img {
    margin-right: 10px;
    margin-top: 4px;
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 50%;
    line-height: 48px;
    text-align: center;
}
.face-notification div {
    flex: 1;
    font-size: 12px;
    line-height: initial;
}
.face-notification p {
    margin: 0;
    font-size: 14px;
    color: #444;
}
.face-notification div .small {
    opacity: 0.4;
    display: block;
}
/* Follow us */

.follow-us {
    opacity: 0.6;
    text-transform: uppercase;
    webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.follow-us i {
    font-size: inherit;
}
.follow-us:hover {
    opacity: 1;
}
/* Portfolio */

.portfolio .row {
    padding: 2px;
    margin-bottom: 0;
}
.portfolio .row .col.s6 {
    padding: 2px 2px 0;
}
.portfolio .row .col.s6 img {
    margin-bottom: 4px;
}
.portfolio .row .col.s6 a:last-child img {
    margin-bottom: 2px;
}
/* Checkout */

.checkout-header {
    box-shadow: none;
    border: none;
    margin: 0;
    background-color: #fafafa;
}
.checkout-header .collapsible-header {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    background-color: #fafafa;
}
.checkout-header .checkout-price {
    font-size: 18px;
}
.checkout-header .collapsible-body {
    padding: 20px;
}
.checkout-header .collapsible-body .checkout-details {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
}
.checkout-image {
    width: 64px;
    margin: 0 10px 0 0;
    border-radius: 4px;
}
.checkout-product-title {
    flex: 1;
}
.checkout-product-title,
.checkout-product-title:last-child {
    margin-bottom: 0;
}
.checkout-product-title h6 {
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    text-transform: uppercase;
}
.checkout-product-title h6 .right {
    font-weight: 400;
}
.checkout-header .input-field {
    display: flex;
}
.checkout-header .discount input {
    flex: 1;
    height: 35px;
}
.checkout-header .discount .btn {
    margin-left: 10px;
}
.discount {
    padding: 20px 0;
}
.subtotal {
    padding: 10px 0 15px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}
.subtotal h6 {
    font-size: 16px;
}
.subtotal span {
    margin: 0;
    padding: 0;
    display: block;
}
.total {
    padding: 20px 0 0;
}
.total .right {
    font-weight: 700;
}
.form-inputs {
    padding: 20px;
}
.form-inputs h4 {
    margin-bottom: 25px;
}
.shipping-address {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #eee;
}
.form-inputs .remember {
    margin: 20px 0;
}
/* Grid */

.grid .row {
    margin-bottom: 0;
}
.grid .row:first-child {
    margin-top: 5px;
}
.grid .col {
    padding: 10px;
    border: 3px solid #fff;
    text-align: center;
}
/* 404 */

.page-404 .title {
    font-size: 72px;
}
.page-404 .valign {
    margin-top: -100px;
}
.page-404 i.ion-android-search {
    color: #444;
}
.page-404 .input-field input[type=search]:focus,
.page-404 .input-field input[type=search]:focus,
.page-404 .input-field input[type=search]:focus {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.page-404 nav .input-field label.active i {
    color: #444;
    opacity: 0.7;
}
/* searchbar */

nav .input-field label i {
    color: rgba(0, 0, 0, 0.3);
}
.nav-wrapper .input-field input[type=search] {
    border: none !important;
    height: 56px;
    max-height: 56px;
    overflow: hidden;
    margin-bottom: 0;
}
/* accordion popout */

.collapsible.popout>li {
    margin: 0 0 5px;
}
.collapsible.popout>li.active {
    margin: 8px -8px;
}
.collapsible.popout .collapsible-header i {
    margin-right: 0;
}
.collapsible.popout .collapsible-body {
    background-color: #fff;
}
/* search map card */

.map-card {
    margin: 20px;
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.map-card-header {
    padding: 20px 20px 20px 40px;
    position: relative;
}
.map-card-header i.left {
    position: absolute;
    top: 14px;
    left: 15px;
}
.map-card-header i.right {
    position: absolute;
    top: 13px;
    right: 25px;
}
.map-card-author span {
    display: block;
    font-size: 14px;
}
/* faq */

ul.faq {
    margin: 0 20px;
}
.faq .collapsible-header i.right {
    margin-right: 0;
}
.faq .collapsible-body {
    background-color: #fff;
}
/* News */

.news-main {
    position: relative;
}
.top-news {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    color: #fff;
}
.top-news-title {
    font-size: 22px;
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
}
.top-news-channel {
    float: left;
    font-size: 12px;
    margin-left: 20px;
    color: #fff;
}
.top-news-category {
    float: right;
    font-size: 12px;
    border-bottom: 1px solid #fff;
    margin-right: 20px;
    color: #fff;
}
.single-news {
    padding: 20px;
    border-bottom: 1px solid #eee;
}
.single-news-title {
    font-size: 20px;
    margin-top: 5px;
    line-height: 1.3;
}
.single-news-channel {
    font-size: 12px;
}
.single-news-category {
    float: right;
    font-size: 12px;
    border-bottom: 1px solid #9e9e9e;
}
.news .comments.collection {
    margin: 0;
}
.news .collection,
.collection .collection-item {
    border: none;
}
.news .collection .collection-item.avatar {
    min-height: 0;
    margin: 20px 0 0;
    padding-left: 60px;
}
.news .collection .collection-item.avatar .text {
    font-size: 14px;
}
.news .collection .collection-item.avatar:first-child {
    margin-top: 5px;
}
.news .collection .collection-item.avatar img {
    margin: 0;
    left: 0;
}
.news .collection .collection-item.avatar .title {
    display: block;
}
.news .collection .collection-item.avatar .date {
    font-size: 11px;
}
.news .collection .collection-item.avatar.reply {
    margin-left: 20px;
    border-left: 1px solid #eee;
    margin-top: 0;
    padding-left: 72px;
}
.news .collection .collection-item.avatar.reply img {
    left: 15px;
}
/* Chart Widget */

.c-widget {
    display: flex;
    align-items: center;
}
.c-widget-figure {
    margin-right: 20px;
    width: 48px;
    height: 48px;
    color: #fff;
    border-radius: 50%;
    line-height: 48px;
    text-align: center;
}
.c-widget-body {
    flex: 1;
}
/* Floating Elements */

.floating-avatar {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: -50px;
    left: 20px;
    border-radius: 50%;
}
.hero-header.scrollable-avatar.scrolled {
    position: fixed;
    top: 0;
    height: 60px;
    overflow: hidden;
}
.hero-header.scrollable-avatar.scrolled .floating-avatar {
    top: 6px;
    width: 48px;
}
.floating-share {
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.floating-share i {
    margin-left: 25px;
    color: #fff;
}
.floating-share i:hover {
    opacity: 0.8;
}
.vertical-line-spacer {
    height: 60px;
    padding-left: 20px;
    border-left: 2px solid #eee;
    margin-left: 50px;
}
.page-fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
}
/* Contact */

.contact {
    position: relative;
    padding: 16px 16px 16px 65px;
}
.contact img {
    position: absolute;
    top: 16px;
    left: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.contact p {
    margin: 1px 0 0;
    color: #37474f;
}
.contact span {
    font-size: 12px;
    font-weight: 300;
}
.contact i {
    font-size: inherit;
}
.contact .time {
    position: absolute;
    top: 20px;
    right: 16px;
    font-weight: 300;
}
.contact .dot {
    width: 12px;
    height: 12px;
    border: 2px solid #fff;
    position: absolute;
    top: 50px;
    left: 33px;
    border-radius: 50%;
    border: 4px solid #2196F3;
}
/* Activity */

.activity .row {
    padding-left: 20px;
    border-left: 2px solid #eee;
    margin-left: 50px;
}
.activity .contact {
    margin-bottom: 20px;
}
.activity .dot {
    width: 20px;
    height: 20px;
    background-color: #fff;
    position: absolute;
    top: 17px;
    left: -42px;
    border-radius: 50%;
}
.activity .date {
    position: absolute;
    top: 18px;
    left: 0;
}
.friends img {
    display: inline-block;
    position: static;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-top: 8px;
}
.friends i {
    font-size: 32px;
}
/* Dropcap */

.dropcap {
    float: left;
    color: #444444;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
}
/* Chat */

.chat .message p {
    margin-bottom: 0;
    font-size: 14px;
}
.chat span {
    font-size: 12px;
}
.chat ul li {
    width: 100%;
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
}
.chat ul li > img {
    float: left;
}
.chat ul li .message {
    margin-left: 55px;
    position: relative;
    padding: 12px 16px;
    border-radius: 4px;
}
.chat ul li .message.first {
    border-top-right-radius: 20px;
}
.chat ul li .message.last {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.chat ul li.message-left {
    margin-bottom: 36px;
}
.chat ul li.message-right .message.first {
    border-top-left-radius: 20px;
    border-top-right-radius: 4px;
}
.chat ul li.message-right > img {
    float: right;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.chat ul li.message-right .message {
    margin-right: 55px;
    margin-left: 0;
    margin-bottom: 2px;
    background-color: #f1f1f1 !important;
}
.chat ul li.message-left > img {
    margin-right: 0;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}
.chat ul li.message-left .message {
    background-color: #f0f8ff;
    margin-bottom: 2px;
}
.message-right > span,
.message-left > span {
    margin-bottom: 20px;
    display: block;
}
.message-left > span {
    margin-left: 55px;
}
.photo-add {
    margin-top: 8px;
}
.photo-add .square-user {
    height: 48px;
    width: 48px;
    margin-right: 4px;
    float: left;
}
.chat-day {
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 30px;
}
/* Notification */

.notification {
    padding: 10px 17px;
    border-radius: 4px;
    position: relative;
    margin-bottom: 20px;
}
.notification p {
    margin-bottom: 0;
}
.notification span {
    font-size: 12px;
}
.close-notification {
    position: absolute;
    top: 2px;
    right: 12px;
}
.notification-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.notification-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
.notification-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.notification-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}
/* Player */

.player-info {
    padding: 24px 20px;
}
.player-info h4 {
    margin: 0 0 8px;
    font-size: 24px;
    font-weight: 300;
    color: #ffffff;
}
.player-info span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 100;
    opacity: 0.85;
}
.player-controllers {
    padding: 20px;
    display: flex;
}
.player-controllers i {
    color: #ffffff;
    flex-grow: 1;
}
.upnext {
    padding: 20px;
}
.next-song {
    padding: 20px 0;
    position: relative;
}
.next-song span:first-child {
    margin-right: 16px;
}
.next-song i {
    position: absolute;
    right: 0;
    top: 12px;
}
/* ToDo */

.choose-date p {
    margin: 0;
    padding: 10px 0;
}
.choose-date p:first-child {
    padding-top: 0;
}
.choose-date p i {
    margin-right: 25px;
    position: relative;
    font-size: 26px;
    top: 3px;
}
.todo input[type="checkbox"]:checked + label {
    text-decoration: line-through;
    color: #9e9e9e;
}
.todo input[type="checkbox"] + label {
    color: inherit;
}
.todo .todo-element {
    position: relative;
    margin: 0;
    padding: 20px;
    border-top: 1px solid #eee;
}
.todo .todo-element:last-child {
    border-bottom: 1px solid #eee;
}
.todo .todo-element span {
    display: block;
    margin-left: 35px;
    font-size: 14px;
    color: #9e9e9e;
}
.todo .todo-element img {
    position: absolute;
    width: 25px;
    height: auto;
    border-radius: 50%;
    top: 20px;
    right: 20px;
}
/* Login / Signup */

.login,
.signup {
    min-height: 100vh;
}
.tool-login,
.tool-signup {
    position: absolute;
    top: 0;
    height: 180px !important;
}
.login-avatar,
.signup-avatar {
    border-radius: 50%;
    border: 4px solid;
    height: 140px;
    width: 140px;
    margin: 30px auto 0;
}
.login-avatar i {
    font-size: 80px;
    line-height: 140px;
    text-align: center;
    display: block;
}
.login-form,
.signup-form {
    padding: 20px;
    margin: 20px;
    border-radius: 2px;
    background-color: #fff;
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    z-index: 99;
}
.login-form h1,
.signup-form h1 {
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
}
/* Carth */

.legend {
    margin-bottom: 20px;
}
.legend h5.uppercase {
    margin-top: 0;
}
.legend p {
    margin-bottom: 0;
}
span.data-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 1px solid;
    margin-right: 7px;
    position: relative;
    top: 1px;
}
/* Lockscreen */

.clock h2 {
    font-size: 48px;
    font-weight: 100;
    margin-top: 80px;
    margin-bottom: 5px;
    color: #ffffff;
}
.clock span {
    color: #ffffff;
    opacity: 0.7;
}
.collection.notify {
    border: none;
    margin: 0;
    padding: 20px;
}
.collection.notify .collection-item {
    padding: 0;
    border-radius: 2px;
    margin-bottom: 5px;
}
.notify-header {
    padding: 10px 20px 10px 65px;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.notify-header img {
    position: absolute;
    top: 10px;
    left: 10px;
}
.notify-author span {
    display: block;
    font-size: 14px;
}
.notify-author span.truncate {
    margin-bottom: 7px;
    opacity: 0.85;
}
.notify-author span.small {
    opacity: 0.5;
}
.unlock {
    color: #fff;
    text-align: center;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    line-height: 43px;
    margin: 0 auto;
    border: 2px solid transparent;
}
.unlock:hover {
    border: 2px solid #fff;
}
/* Masonry */

.grid-item {
    width: 50%;
    height: auto;
    float: left;
    margin: 0;
    padding: 1px;
}
/* Filter */

.controls {
    text-align: right;
    margin-bottom: 20px;
}
.controls button {
    display: inline-block;
    padding: 7px 12px;
    font-size: 14px;
    background: #eee;
    border: 0;
    color: inherit;
    border-radius: 2px;
    cursor: pointer;
    color: inherit;
    transition: all 0.3s ease-in;
    -webkit-transition: all 0.3s ease-in;
}
.controls button.active {
    background: #2196F3;
    color: #fff;
}
.controls label {
    margin-right: 8px;
}
.mix,
.gap {
    float: left;
    width: 50%;
    margin: 0;
}
.mix {
    display: none;
    padding: 1px;
}
.mix figure {
    display: none;
}
/* Gallery Card */

.grid-item.gallery-item-card {
    background-color: #fff;
    border-radius: 2px;
    -webkit-box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    margin: 0 3px 6px;
    width: calc(50% - 6px);
}
.gallery-item-header {
    padding: 10px 20px;
    position: relative;
}
.gallery-item-header img {
    position: absolute;
    top: 20px;
    left: 20px;
}
.gallery-item-header span {
    display: block;
    font-size: 14px;
}
/* Gallery Fullwidth */

.gallery-fullwidth .mix,
.gallery-fullwidth .gap {
    float: none;
    width: 100%;
    margin: 0;
}
/* filter */

#filter.grid .mix {
    width: 50%;
}
#filter .mix {
    width: 100%;
}


/*Sticky Footer*/
.main-content{
    flex: 1 0 auto;
}
#content{
    display: flex;
    min-height: 93vh;
    flex-direction: column;
}

/*List style card 1*/
.list-style-colec-1{
    padding:0px;
    margin:0px;
}
.list-style-item{
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
    display: flex;
    align-items: center;
}
.info-item{
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    padding: 0px;
    margin: 0px;
}
.info-item li{
    padding-right: 10px;
    display: inline;
    i{
        font-size: 16px;
    }
    &:first-child{
        font-weight: 700;
        text-transform: capitalize;
        font-size: 16px;
        color: #333;
    }
    &.hotline{
        color: #333;
    }
    &.star{
        color: #333;
    }
    &.new{
        color: #fff;
        background-color: #26a69a;
        border-radius: 3px;
        display: inline-block;
        padding: 0px 5px;
        font-size: 12px;

    }
    &.refresh{
        padding-left: 10px;
        color: #8bc34a;
        font-size: 18px;
    }
}
@media(max-width: 767px){
    .list-style-item{
        padding: 3px 0px;
        display: flex;
        flex-wrap: wrap;
    }
    // .info-item li{
    //     i{
    //         display: none;
    //     }
    // }
    .collapsible-header{
        padding: 0px !important;
    }
    .collapsible{
        margin-bottom: 0px !important;
    }
}
@media(min-width: 992px){
    .info-item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px;
    }
}
.collapsible{
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    border: none;
    margin-bottom: 5px;
}
.collapsible-header{
    border: none;
    padding: 5px 15px;
}
.collapsible-body{
    padding: 5px 0px;
    background-color: #fff;
}
.list-bottom{
    display: inline-block;
    width: 100%;
    padding: 0px;
    margin: 0px;
    li{
        float: left;
        font-size: 13px;
        padding-right: 5px;
        div{
            cursor: pointer;
            float: left;
            padding-right: 15px;
            &.okCustomer_status{
                position: relative;
                padding-left: 20px;
                &:before{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border: 1px solid #26a69a;
                    border-radius: 100%;
                }
                &:after{
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    content: "";
                    width: 11px;
                    height: 11px;
                    background-color: #26a69a;
                    border-radius: 100%;
                }
            }
            &.noCustomer_status{
                position: relative;
                padding-left: 20px;
                &:before{
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    content: "";
                    width: 15px;
                    height: 15px;
                    border: 1px solid #666;
                    border-radius: 100%;
                }
                &:after{
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    content: "";
                    width: 11px;
                    height: 11px;
                    background-color: #fff;
                    border-radius: 100%;
                }
            }
        }
        label{
            font-size: 13px;
            padding-left: 25px;
            line-height: 18px !important;
            background-color: #f00;
            a{
                cursor: hand;
            }
        }
    }
}
.nhanvien{
    color: #2196f3;
    font-size: 13px;
    i{
        font-size: 13px;
    }
}

/*action-item*/
.action-item{
    float: right;
    a{
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        text-align: center;
        border: 1px solid #ddd;
        i{
            font-size: 18px;
            color: #666;
            line-height: 28px;
        }
        &:hover{
            border-color:#2396f3;
            i{
                color: #2396f3;
            }
        }
    }
}
.price{
    color: #f44336;
    font-weight: 700;
}
.info-item .text-item p{
    margin-bottom: 0px;
}
.list-style-colec-1 .list-style-item>a{
    width: 30px;
}
.list-sfs{
    margin:0px;
    padding:0px;
    list-style:none;
    justify-content:space-between;
    align-items:center;
}
.list-sfs li{
    // width:33.33%;
    width:100%;
}
.list-sfs a{
    padding:13px 10px;
    font-size:16px;
    color:#fff;
    display:block;
    background:#2196F3;
    text-align:center;
    text-transform: capitalize;
}
.list-sfs a i{
    font-size:16px;
    margin-right:5px;
}
@media(max-width: 767px){
    .list-sfs a{
        padding: 5px 10px;
    }
}
.statics{
    padding: 15px;
    justify-content: space-between;
    align-items: center;
}

.list-style-colec-2,
.list-style-colec-3{
    margin:0px;
}
.list-action{
    display:flex;
    flex-direction:column;
    a{
        display:block;
        width:50px;
        text-align:center;
        i{
            font-size:20px;
            color:#c62828;
        }
    }
    a:first-child{
        i{
            color:#1e88e5;
        }
    }
}

.list-style-colec-3{
    .list-style-item{
        border-bottom:0px;
        margin: 12px 0px;
    }
}
/*Flex box*/
.flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

#tsw-customer-create>li{
    a{
        font-size:16px;
    }
}

/*.main-content{
    position: absolute;
    z-index: 1;
    top: 45px;
    bottom: 48px;
    left: 0;
    width: 100%;
    background: #ccc;
    overflow: hidden;
}

#scroller {
    position: absolute;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    width: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -o-text-size-adjust: none;
    text-size-adjust: none;
}*/